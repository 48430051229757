import PricingBox from "./PricingBox";
import '../../css/Pricing/Pricing.css'
import  { Link } from "react-router-dom";
import EmailBox from "../EmailBox";
import robowar from '../../images/robowar.png';
import robowar2 from '../../images/robowar2.png';
import { ReactComponent as SmallUpwardWave } from "../../images/small_upward_wave.svg";
import { ReactComponent as SmallDownwardWave } from "../../images/small_downward_wave.svg";



function Pricing() {
    const pricing_plans = [
        {"pricingHeader":"Basic", "pricingImage":"Bot", "pricingCost":"$365", "pricingPerMonth":"100", "pricingFeatureArray":["✔️","X","X","X","500","X","X","X","X"]},
        {"pricingHeader":"Pro", "pricingImage":"headsetbot", "pricingCost":"$1,250", "pricingPerMonth":"365", "pricingFeatureArray":["✔️","3 Forms","All Standard & 5 Custom","✔️","1000","X","X","X","X"]},
        {"pricingHeader":"Elite", "pricingImage":"BotEnterprise", "pricingCost":"", "pricingPerMonth":"", "pricingFeatureArray":["✔️","Unlimited","Unlimited","✔️","Unlimited","✔️","✔️","✔️","✔️"]}
    ]

    return (
    <div className="pricing-page-container">
        <img className="robowar1" src={robowar} />
        <img className="robowar2" src={robowar2} />
        <div className="pricing-page-header">
            <h1>Unlock Value with Every Plan</h1>
            <p>Pricing Tailored to Your Chatbot Needs!</p>
        </div>
      <div className="pricing-container">
        <table className="titles-table">
            <tbody>
            <tr><th className="row-header plan-header"></th></tr> 
            <tr><th className="row-header plan-image"></th></tr> 
            <tr><th className="row-header plan-pricing"></th></tr> 
            <tr><th className="row-header plan-installation-pricing">Installation Fee</th></tr> 
            <tr><td className="row-header plan-feature">FAQ</td></tr> 
            <tr><td className="row-header plan-feature">Forms</td></tr> 
            <tr><td className="row-header plan-feature">Ticket Generation</td></tr> 
            <tr><td className="row-header plan-feature">Media Sharing</td></tr>
            {/* <tr><td className="row-header plan-feature">Media - Videos</td></tr> */}
            <tr><td className="row-header plan-feature">User Sessions</td></tr>
            <tr><td className="row-header plan-feature">POC</td></tr>
            <tr><td className="row-header plan-feature">Offensive Language Detection</td></tr>
            <tr><td className="row-header plan-feature">Sentiment Analysis</td></tr>
            <tr><td className="row-header plan-feature">Voice Bot</td></tr>
            </tbody>
        </table>
        {
            pricing_plans.map((pricing_plan, index) => {
                    return <PricingBox key={index} pricingHeader={pricing_plan.pricingHeader} pricingImage={pricing_plan.pricingImage} pricingCost={pricing_plan.pricingCost} pricingPerMonth={pricing_plan.pricingPerMonth} pricingFeatureArray={pricing_plan.pricingFeatureArray}/>
                })
        }
        {/* <table>
        <tr>
            <th></th>
            <th className="plan_header">Basic</th>
            <th className="plan_header">Pro</th>
            <th className="plan_header">Enterprise</th>
        </tr>
        
        <tr>
            <th></th>
            <th><img className="plan_image" src={image} alt="Einbot" /></th>
            <th><img className="plan_image" src={image} alt="Einbot" /></th>
            <th><img className="plan_image" src={image} alt="Einbot" /></th>
        </tr>
        <tr>
            <th></th>
            <th>₹30,000</th>
            <th>₹1,00,000</th>
            <th><Link to="/contact"><button>Contact us</button></Link></th>
        </tr>
        <tr>
            <th></th>
            <th>₹5000/month</th>
            <th>₹10,000/month</th>
            <th></th>
        </tr>
        <tr>
            <th className="row-header">FAQ</th>
            <td className="basic">✔️</td>
            <td className="pro">✔️</td>
            <td>✔️</td>
        </tr>
        <tr>
            <th className="row-header">Forms</th>
            <td className="basic">X</td>
            <td className="pro">3 Forms</td>
            <td>Unlimited</td>
        </tr>
        <tr>
            <th className="row-header">Ticket generation</th>
            <td className="basic">X</td>
            <td className="pro">Standard tickets, 5 custom tickets</td>
            <td>Unlimited</td>
        </tr>
        <tr>
            <th className="row-header">Media - Images</th>
            <td className="basic">X</td>
            <td className="pro">✔️</td>
            <td>✔️</td>
        </tr>
        <tr>
            <th className="row-header">Media - Video</th>
            <td>X</td>
            <td>✔️</td>
            <td>✔️</td>
        </tr>
        <tr>
            <th className="row-header">User Sessions</th>
            <td>upto 250</td>
            <td>upto 500</td>
            <td>Unlimited</td>
        </tr>
        <tr>
            <th className="row-header">Offencive Language Detection</th>
            <td>X</td>
            <td>Add-on</td>
            <td>✔️</td>
        </tr>
        <tr>
            <th className="row-header">Sentiment Analysis</th>
            <td>X</td>
            <td>Add-on</td>
            <td>✔️</td>
        </tr>
        <tr>
            <th className="row-header">POC</th>
            <td>X</td>
            <td>Add-on</td>
            <td>✔️</td>
        </tr>
        <tr>
            <th className="row-header">Voice bot</th>
            <td>X</td>
            <td>Add-on</td>
            <td>✔️</td>
        </tr>
    </table> */}
      </div>
      <div className="pricing-contact-container">
        <div className="pricing-small-wave-container">
            <SmallUpwardWave className="small-upward-wave" />    
        </div>
        <EmailBox emailTitle="Build your own custom plan today" flexDirection="row" />
        <div className="pricing-small-wave-container">
            <SmallDownwardWave className="small-upward-wave" />
        </div>
       </div>
    </div>
    )
  }
  
  export default Pricing;