
import '../../css/Products/Products.css'
import ProductBox from "./ProductBox";
import { useEffect, useState } from 'react';
import {ReactComponent as SmallUpwardWave} from '../../images/small_upward_wave.svg';

function Products() {

  const [productsContent,setProductsContent]=useState([]);

  const getData=()=>{
    fetch('productsContent.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        // console.log(myJson['productsList']);
        setProductsContent(myJson['productsList'])
      });
  }
  useEffect(()=>{
    getData()
  },[])  

    return (
        <div className="products-container">
          {
            productsContent.map((product, index) => (
              <div key={index} className='product-container'>
                <ProductBox index={index} productTitle={product.product} productDescription={product.description} productFeaturesArray={product['featureList']}/>
                <div className="products-small-wave-container">
                  <SmallUpwardWave className="small-upward-wave" />
                </div>
              </div>
            ))
          }
        </div>
    );
  }
  
  export default Products;