import '../../css/Products/ProductBox.css';
import ProductFeature from './ProductFeature';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import EmailBox from '../EmailBox';
function ProductBox({index, productTitle, productDescription, productFeaturesArray}) {

    const [productFeatures, setProductFeatures] = useState(productFeaturesArray);
    const [lastShownProductFeatureIndex, setLastShownProductFeatureIndex] = useState(0);
    const [isLooping, setIsLooping] = useState(true);
    const handleOnProductFeatureClick = useCallback((index) => {
      // console.log('index', index);
      setIsLooping(false);
      const newProductFeatures = [...productFeatures];
      if (lastShownProductFeatureIndex !== index) {
        newProductFeatures[lastShownProductFeatureIndex].showDescription = false;
        setLastShownProductFeatureIndex(index);
      }
      newProductFeatures[index].showDescription = !newProductFeatures[index].showDescription;
      setProductFeatures(newProductFeatures);
    }, [lastShownProductFeatureIndex]);

    
    useEffect(() => {
      const intervalId = setInterval(() => {
        // console.log('current time', new Date());
        if (!isLooping) {
          return clearInterval(intervalId);
        }
        setProductFeatures(c => {
          const newProductFeatures = [...c];
          newProductFeatures[lastShownProductFeatureIndex].showDescription = false;
          newProductFeatures[(lastShownProductFeatureIndex + 1) % newProductFeatures.length].showDescription = true;
          setLastShownProductFeatureIndex((lastShownProductFeatureIndex + 1) % newProductFeatures.length);
          return newProductFeatures;
        });
      }, 6000);
      return () => clearInterval(intervalId);
    }, [productFeatures, lastShownProductFeatureIndex]);

    return (
      <>
        <div className="product-box-container">
          <div className="product-text-pane">
              <div className="product-header">
                <h1>{productTitle}</h1>
                <h3>{productDescription}</h3>
              </div>
              {productFeatures.map((productFeature, index) => (
                <ProductFeature key={index} index={index} 
                showDescription={productFeature.showDescription}
                title={productFeature.title} description={productFeature.description} 
                handleOnProductFeatureClick={handleOnProductFeatureClick}/>
              ))}
          </div>
          <div className="product-image-pane">
              <div className="product-image">
                  <img src={require('../../images/'+productFeatures[lastShownProductFeatureIndex]["image"]+'.png')} alt="Einbot" />
              </div>
          </div> 
        </div>
        {index === 0 ?
            (<EmailBox emailTitle={'Accelerate your business with Einbot'} flexDirection={'row'}/>) : null
        }
      </>
    );
  }
  
  export default ProductBox;