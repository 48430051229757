import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/Header.css';
import logo from '../images/einbot_logo.svg';
import asttecs from '../images/asttecs.png';
import  { Link } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const location = useLocation();

  // Function to determine what to show in the header based on the route
  const getHeaderSpecialClass = () => {
    switch (location.pathname) {
      case '/':
        return 'fixed-header-class';
      default:
        return '';
    }
  };


  return (
    <header className={`header-class ${getHeaderSpecialClass()} ${scrollY > 100 ? 'scrolled' : ''}`}>
      <div className="logo">
        <Link to="/">
            <img src={logo} alt="Einbot" />
        </Link>
      </div>
      <div className="logo-text">
        <Link to="/">
          <p>Einbot</p>
        </Link>
      </div>
      <nav className={isMenuOpen ? 'open' : ''} onClick={() => setIsMenuOpen(false)}>
        <ul >
          <li>
            <Link to="/products">Products</Link>
            {/* <a href="/">Products</a> */}
          </li>
          <li>
            <Link to="/pricing">Pricing</Link>
            {/* <a href="/about">Pricing</a> */}
          </li>
          <li className="dropdown">
            {/* <a href="/services">Integrations  */}
            <Link to="/integrations">Integrations
                {/* <span >
                <svg className="menu-arrow" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M12.67 6L8 10.67 3.33 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg></span> */}
            </Link>
            {/* </a> */}
            {/* <div className="dropdown-container">
                <div className="dropdown-content">
                    <a href="/integrations"><img className='dropdown-logo' src={logo} alt="Einbot" />Vtiger CRM</a>
                    <a href="/integrations"><img className='dropdown-logo' src={whatsapp_logo} alt="Einbot" />Whatsapp</a>
                    <a href="/integrations"><img className='dropdown-logo' src={logo} alt="Einbot" />Website Chat</a>
                    <a href="/integrations"><img className='dropdown-logo' src={logo} alt="Einbot" />Wordpress</a>
                    <a href="/integrations"><img className='dropdown-logo' src={logo} alt="Einbot" />Facebook Messenger</a>
                    <a href="/integrations"><img className='dropdown-logo' src={logo} alt="Einbot" />Instagram</a>
                </div>
            </div> */}
          </li>
          <li>
            <Link to="/contact">Contact</Link>
            {/* <a href="/contact">Contact</a> */}
          </li>
        </ul>
      </nav>
      <div className="asttecs-container">
        <p>In partnership with</p>
        <a href="https://www.asttecs.com/" target="_blank" rel="noreferrer">
        <img className='asttecs-logo' src={asttecs} alt="asttecs"/>
        </a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
    </header>
  );
}

export default Header;
