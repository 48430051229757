import React, { useState } from 'react';
import '../css/Footer.css';
import asttecs from '../images/asttecs.png';

function Footer() {
 
  return (
    <footer>
      <div className="asttecs-container">
      <a href="https://www.asttecs.com/" target="_blank" rel="noreferrer">
        <p>In partnership with</p>
        <img className='asttecs-logo' src={asttecs} alt="asttecs"/>
        </a>
      </div>
        <p>Copyright © 2023 Einbot Pvt Ltd - All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
