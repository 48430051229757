
import '../css/Contact.css'
import { useState } from 'react';

function Contact() {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [company, setCompany] = useState("")
  const [message, setMessage] = useState("")
  const [formIsValid, setFormIsValid] = useState(false)
  const [errors, setErrors] = useState("")
  const sample_url = "https://hooks.zapier.com/hooks/catch/16154227/31vrfzg/";
  const submit = (e) => {
    e.preventDefault()
    //Email
    if (!email) {
      setFormIsValid(false);
      setErrors("Email cannot be empty");
      return
    }
    if (!name) {
      setFormIsValid(false);
      setErrors("Name cannot be empty");
      return
    }
    fetch(sample_url, {
      method: "POST",
      body: JSON.stringify({ name, email, phoneNo, company, message }),
    })
    setEmail("")
    setName("")
    setPhoneNo("")
    setCompany("")
    setMessage("")
    setErrors("")
    setFormIsValid(false);
  }
  return (
    <div className="contact-page-container">
      <div className="contact-form-pane">
        <div className="contact-form-pane-header">
          <h1>Together We Innovate</h1>
          <h2>Contact Us to Craft Your Solution</h2>
        </div>
        <form className="contact-form"
          onSubmit={submit}>
          <p className='contact-form-field-label'>Name:</p>
          <input type="text" placeholder="Albert Einstein" 
            value={name}
            onChange={(e) => setName(e.target.value)}/>
          <p className='contact-form-field-label'>Email:</p>
          <input type="email" placeholder="albert@einbot.com" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}/>
          <p className='contact-form-field-label'>Phone:</p>
          <input type="text" placeholder="+49 111 1111 1111"  
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}/>
          <p className='contact-form-field-label'>Company:</p>
          <input type="text" placeholder="Einbot"  
            value={company}
            onChange={(e) => setCompany(e.target.value)}/>
          <p className='contact-form-field-label'>Message:</p>
          <textarea className="contact-form-message-input" type="text" placeholder="Hey! &#10;Let's build!&#10;Thanks,&#10;Albert"  
            value={message}
            onChange={(e) => setMessage(e.target.value)}/>
          <button>Submit</button>
          <p className='contact-form-error'>{`${errors ? errors: '' }`}</p>
        </form>
      </div>   
      <div className="contact-info-pane">
        <div className="contact-info-item">
        <h3>Email</h3>
        <p>hello@einbot.com</p>
        </div>
        <div className="contact-info-item">
        <h3>Facebook</h3>
        <p>Einbot</p>
        </div>
        <div className="contact-info-item">
        <h3>Twitter</h3>
        <p>@einbot_ai</p>
        </div>
        <div className="contact-info-item">
        <h3>Instagram</h3>
        <p>@einbot.ai</p>
        </div>
        <div className="contact-info-item">
        <h3>Threads</h3>
        <p>einbot.ai</p>
        </div>
      </div>   
    </div>
  );
}

export default Contact;