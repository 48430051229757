import '../../css/Pricing/PricingBox.css'
import EmailBox from '../EmailBox';

function PricingBox({pricingHeader, pricingImage, pricingCost, pricingPerMonth, pricingFeatureArray}) {
    return (
      <div className="pricing-box-container">
        <table>
          <tbody>
            <tr>
              <th className="plan-header">{pricingHeader}</th>
            </tr>
            <tr>
              <th className="plan-image"><img src={require('../../images/'+pricingImage+'.png')} alt="Einbot" /></th>
            </tr>
            <tr>
              <th className="plan-pricing">
              <span className='plan-pricing-pretext'>{`${pricingPerMonth ? 'starting at\u000A' : ''}`}</span>
              <span className='plan-pricing-currency'>{`${pricingPerMonth ? '$' : ''}`}</span>
              {pricingPerMonth ? pricingPerMonth: <EmailBox/>}<span className='plan-pricing-period'>{`${pricingPerMonth ? '/month' : ''}`}</span> </th>
            </tr>
            <tr>
              <th className='plan-installation-pricing'>{pricingCost}</th>
            </tr>
            {
              pricingFeatureArray.map((feature, index) => {
                  return <tr key={index}>
                    <td className='plan-feature'>{feature}</td>
                  </tr> 
              })
            }
          </tbody>
        </table>

      </div>
    );
  }
  
  export default PricingBox;