import React, { useState } from 'react';
import '../../css/Home/Banner.css';
import backgrounmoviewithchat from '../../video/backgroundwithchat_negative_blurred.mp4'
import logo from '../../images/einbot_logo.svg';

function Banner() {
 
  return (
    <div className="banner-container">
        <div className="banner-text">
            <div className="banner-logo-container">
              <img className="banner-logo" src={logo} alt="Einbot" />
            </div>
            <h1>Transforming Customer Conversations</h1>
            <h2>Unleash the Potential of AI-Powered Chatbots!</h2>
        </div>
        {/* <div className="full-banner-video">
            <video src={bannervid}  autoPlay muted loop>
            </video>
        </div> */}
        {/* <div className="banner-video-overlay">
            <video src={videoNoBG}  autoPlay muted loop>
            </video>
        </div> */}
        <div className="banner-video">
            <video src={backgrounmoviewithchat}  autoPlay muted loop playsinline>
            </video>
        </div>
    </div>
  );
}

export default Banner;
