import React, { useState } from 'react';
import '../../css/Home/BenifitBox.css';
import { nbsp, breakline } from '../../htmlCodes';

function BenifitBox({boxHeader, boxImage, boxText, linkElement}) {
  return (
    <div className="benifit-box-container">
      <div className="benifit-box-container-text">
        <h3>{boxHeader}</h3>
        <p>{boxText}</p>
        <div className='benifit-box-link'>{linkElement}</div>
      </div>
      <img src={require('../../images/'+boxImage)} alt="Einbot" />
    </div>
  );
}

export default BenifitBox;