import { ReactComponent as SmallUpwardWave } from "../../images/small_upward_wave.svg";
import { ReactComponent as SmallDownwardWave } from "../../images/small_downward_wave.svg";
import '../../css/Integrations/Integrations.css';
import EmailBox from "../EmailBox";

function Integrations() {
    const communicationChannels = [
            {
                "channelName":"WhatsApp",
                "channelImage":"whatsapp",
                "description":"Experience seamless integration with WhatsApp, enhancing customer interaction with the power of Einbot."
            },
            {
                "channelName":"Facebook Messenger",
                "channelImage":"facebook",
                "description": "Boost your customer service by integrating Einbot with Facebook Messenger, promoting round-the-clock engagement."
            },
            {
                "channelName":"Website Chat",
                "channelImage":"wwwchat",
                "description":"Enhance your website's user experience with Einbot, ensuring immediate and effective responses to visitor queries."
            },
            {
                "channelName":"Telegram",
                "channelImage":"telegram",
                "description":"Extend your customer support capabilities with Einbot, now integrable with Telegram for dynamic customer interactions."
            },
            {
                "channelName":"Instagram",
                "channelImage":"instagram",
                "description":"Propel your customer interaction to new heights by integrating Einbot with Instagram, enriching the customer journey."
            },
            {
                "channelName":"Slack",
                "channelImage":"slack",
                "description":"Streamline team communication and productivity by integrating Einbot with Slack, encouraging efficient collaboration."
            }
    ]
    const softwareIntegrations = [
            {
                "softwareName":"Salesforce CRM",
                "softwareImage":"salesforce",
                "description":"Supercharge your Salesforce operations with our ChatBot, facilitating efficient customer management and data tracking."
            },
            {
                "softwareName":"Vtiger CRM",
                "softwareImage":"vtiger",
                "description":"Enhance your Vtiger CRM experience through seamless integration with our ChatBot, boosting your customer relationship management efficacy."
            },
            {
                "softwareName":"Zoho CRM",
                "softwareImage":"zoho",
                "description":"Propel your customer engagement with ZOHO CRM by integrating our ChatBot, enriching the customer journey with timely responses."
            },
            {
                "softwareName":"EspoCRM",
                "softwareImage":"espo",
                "description":"Improve your Espo CRM operations by integrating our ChatBot, ensuring prompt and personalized customer interactions."
            },
            {
                "softwareName":"Zendesk",
                "softwareImage":"zendesk",
                "description":"Amplify your Zendesk's customer support capabilities by seamlessly integrating our ChatBot, driving customer satisfaction and loyalty."
            },
            {
                "softwareName":"Freshdesk",
                "softwareImage":"freshdesk",
                "description":"Elevate your Freshdesk experience with our ChatBot integration, streamlining support requests and enhancing customer experience."
            }
    ]
    return (
      <div className="integrations-container">
         <div className="communication-channels-banner">
            <h1 className="communication-channels-banner-title">Maintain seamless dialogue across various platforms</h1>
            <h3 className="communication-channels-banner-subtitle">Cater to your customers' preferences by integrating Einbot with their preferred communication channels, ensuring your constant presence and availability.</h3>
            <div className="communication-channels-container">
                {
                    communicationChannels.slice(0,3).map((channel, index) => (
                        <div key={index} className="communication-channel-box">
                            <img className="communication-channel-box-image" src={require('../../images/'+channel.channelImage+'.png')} alt={channel.channelName} />
                            <h2 className="communication-channel-box-title">{channel.channelName}</h2>
                            <p className="communication-channel-box-description">{channel.description}</p>
                        </div>
                ))
            }
            </div>
            <div className="communication-channels-container">
            {
                communicationChannels.slice(3,6).map((channel, index) => (
                    <div key={index} className="communication-channel-box">
                        <img className="communication-channel-box-image" src={require('../../images/'+channel.channelImage+'.png')} alt={channel.channelName} />
                        <h2 className="communication-channel-box-title">{channel.channelName}</h2>
                        <p className="communication-channel-box-description">{channel.description}</p>
                    </div>
            ))
            }
            </div>
         </div>
         <div className="integrations-small-wave-container">
            <SmallUpwardWave className="small-upward-wave" />    
        </div>
        <EmailBox emailTitle="Accelerate your business with Einbot" flexDirection="row" />
        <div className="integrations-small-wave-container">
            <SmallDownwardWave className="small-upward-wave" />
        </div>
        <div className="software-integrations-banner">
            <h1 className="software-integrations-banner-title">Enhance Productivity from Your Preferred Software</h1>
            <h3 className="software-integrations-banner-subtitle">Seamlessly merge Einbot with your existing marketing and analytics tools. Simplify routine customer relation tasks to free up more time for significant priorities.</h3>
            <div className="software-integrations-container">
                {
                    softwareIntegrations.slice(0,3).map((software, index) => (
                        <div key={index} className="software-integration-box">
                            <img className="software-integration-box-image" src={require('../../images/'+software.softwareImage+'.png')} alt={software.softwareName} />
                            <h2 className="software-integration-box-title">{software.softwareName}</h2>
                            <p className="software-integration-box-description">{software.description}</p>
                        </div>
                    ))
                }
            </div>
            <div className="software-integrations-container">
                {
                    softwareIntegrations.slice(3,6).map((software, index) => (
                        <div key={index} className="software-integration-box">
                            <img className="software-integration-box-image" src={require('../../images/'+software.softwareImage+'.png')} alt={software.softwareName} />
                            <h2 className="software-integration-box-title">{software.softwareName}</h2>
                            <p className="software-integration-box-description">{software.description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className="integrations-small-wave-container">
            <SmallUpwardWave className="small-upward-wave" />
        </div>
      </div>
    );
  }
  
  export default Integrations;