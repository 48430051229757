import React, { useState } from 'react';
import BenifitBox from './BenifitBox';
import '../../css/Home/Benifits.css';
import robowar from '../../images/robowar.png';
import robowar2 from '../../images/robowar2.png';
import { Link } from 'react-router-dom';
import { nbsp, breakline } from '../../htmlCodes';


function Benifits() {
  const benifits_data = [{"boxHeader":"Round the Clock Support", 
                        "boxText":"Your customers don't operate on a 9-to-5 schedule, and neither should your support. A chatbot ensures that any time a customer has a question or needs help, they receive an instant response, even if it's in the middle of the night.",
                        "link_element":<div/>,
                        "image":"247.png"},
                        {"boxHeader":"Seamless Integration", 
                        "boxText":"HotelBot Solutions seamlessly integrates with your existing systems, including your website, booking engine, and CRM, ensuring a seamless guest experience and streamlined operations.",
                        "link_element":<Link to="/integrations">Learn more{nbsp}{">"}</Link>,
                        "image":"integrations.png"},
                        {"boxHeader":"Lead Generation", 
                        "boxText":"Chatbots can engage users in interactive conversations, capture relevant information, and even schedule appointments, making them a powerful tool in nurturing and converting potential leads. Their interactive nature makes it more likely for users to engage, providing better lead qualification data in the process.",
                        "link_element":<div/>,
                        "image":"lead_generation.png"},
                        {"boxHeader":"Cost-Efficient", 
                        "boxText":"Instead of maintaining a large support team around the clock, chatbots can handle a significant volume of queries without any additional costs. This helps businesses save on operational expenses without compromising on customer experience.",
                        "link_element":<Link to="/pricing">Browse our plans{nbsp}{">"}</Link>,
                        "image":"cost.png"},]

  return (
    <div className="benifits-container">
        <h1>Why your business needs a Chatbot</h1>
        {
            benifits_data.map((benifit, index) => {
                return <BenifitBox key={index} boxHeader = {benifit.boxHeader} boxImage= {benifit.image} boxText={benifit.boxText} linkElement={benifit.link_element}/>
            })
        }
        <img className="robowar1" src={robowar} />
        <img className="robowar2" src={robowar2} />
    </div>
  );
}

export default Benifits;