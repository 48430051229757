import '../../css/Products/ProductFeature.css';
import React, { useState } from 'react';

function ProductFeature({title, index, showDescription, description, isDescriptionVisible, handleOnProductFeatureClick}) {
    // const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

    // const toggleDescription = () => {
    //     setIsDescriptionVisible(!isDescriptionVisible);
    // };
    // console.log('index', index);
    return (
        <div className={`product-feature ${showDescription ? 'product-feature-expanded' : ''}`}>
            <div className='product-feature-title' onClick={()=> handleOnProductFeatureClick(index)}>
            <span>
                <svg className="product-feature-menu-arrow" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><defs></defs>
                    <path d={`${!showDescription ? "M6 3.33L10.67 8 6 12.67" : "M12.67 6L8 10.67 3.33 6"}`} stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                <svg className="product-feature-menu-arrow-small" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><defs></defs>
                    <path d={`${!showDescription ? "M4.5 2.22L7.45 6 4.5 9.45" : "M9.45 4.5L6 7.45 2.22 4.5"}`} stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
            </span>
            <h2>{title}</h2>
            </div>
            <div className={`product-feature-description-container ${showDescription ? 'product-feature-description-container-visible' : ''}`}>
                <p className='product-feature-description'>{description}</p>
            </div>
        </div>
    );
  }
  
  export default ProductFeature;