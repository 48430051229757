import Banner from "./Banner";
import Benifits from "./Benifits";
import EmailBox from "../EmailBox";
import {ReactComponent as UpwardWave} from "../../images/upward_wave.svg";
import {ReactComponent as DownwardWave} from "../../images/downward_wave.svg";
import { ReactComponent as MirrorUpwardWave } from "../../images/mirror_upward_wave.svg";
import '../../css/Home/Home.css';
import { useEffect, useState } from "react";

function Home() {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setHasScrolled(false); // Reset when user is back at the top
      } 
      else if (!hasScrolled) {
        window.scrollTo({
          top: window.innerHeight, // Scroll down by one viewport height
          behavior: 'smooth'
        });
        setHasScrolled(true); // Ensure this only happens once
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);
  return (
    <div className="home-container">
       <Banner />
       <div className="home-email-container">
          {/* <img src={upward_wave} alt="upward_wave" className="upward_wave" /> */}
          {/* <MirrorUpwardWave className="upward_wave" /> */}
          <EmailBox  emailTitle="Ready to chat? We'd love to hear from you!" flexDirection="row" />
          <DownwardWave className="downward_wave" />
          {/* <img src={downward_wave} alt="downward_wave" className="downward_wave" /> */}
        </div>
       <Benifits />
    </div>
  );
}

export default Home;