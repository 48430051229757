import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home/Home';
import  { HashRouter, Routes, Route } from "react-router-dom";
import Contact from './components/Contact';
import Pricing from './components/Pricing/Pricing';
import Products from './components/Products/Products';
import React, { useState } from 'react';
import Integrations from './components/Integrations/Integrations';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar
} from "@chatscope/chat-ui-kit-react";

let serverUrl = "http://192.168.0.188:3001";


// define a function that runs every 60 seconds and checks if serverUrl is reachable and if not hide iframe using react useEffect
function checkServer() {
  let iframe = document.querySelector('#chatdiv');
  let xhr = new XMLHttpRequest();
  xhr.open('GET', serverUrl, true);
  xhr.send();
  xhr.onload = function() {
    if (xhr.status != 200) { // if server is not reachable
      iframe.style.display = "none";
    } else {
      iframe.style.display = "block";
    }
  }
}

function App() {
const [showChat, setShowChat] = useState(true);
  let x = (
    <div className="App">
      <HashRouter>
        <Header />
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/products" element={<Products/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/pricing" element={<Pricing/>}/>
            <Route path="/integrations" element={<Integrations/>}/>
          </Routes>
          { showChat ? <iframe className="chat" src={serverUrl}></iframe> : null }
          {/* <div className ="chat">
            <MainContainer>
              <ChatContainer>
                <MessageList>
                  <Message
                    model={{
                      direction: "outgoing",
                      message: "Hello my friend",
                      sentTime: "just now",
                      sender: "Joe",
                    }}>
                      <Avatar src={logo} name="Akane" />
                    </Message>

                  <Message model={{
                      direction: "incoming"
                    }}>
                      <Avatar src={logo} name="Akane" />
                      <Message.ImageContent src={logo} alt="Akane avatar" width={200} />
                  </Message>
                </MessageList>
                <MessageInput placeholder="Type message here" />
              </ChatContainer>
            </MainContainer>
          </div> */}
        <Footer />
      </HashRouter>
    </div>
  );

  // checkServer();
  // // check if src is reachable if not hide iframe
  // let iframe = document.querySelector('#chatdiv');

  return x;
}

export default App;
