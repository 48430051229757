import { Link } from 'react-router-dom';
import '../css/EmailBox.css'

function EmailBox({emailTitle, flexDirection}) {
    return (
      <>
        {/* create a form to input an emailid and a button that days contact me */}
        <div className={`email-box-container ${flexDirection == 'row' ? 'email-box-container-row' : '' } `}>
            <p>{emailTitle}</p>
            <div className="email-form-container">
              {/* <input type="text" placeholder="Enter your email id" /> */}
              <Link to="/contact">
                <button>contact us</button>
              </Link>
            </div>
        </div>
      </>
    );
  }
  
  export default EmailBox;